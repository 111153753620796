import React from "react";
import WhereToSell from '../images/how-to-sell/nav-icons/where_to_sell.inline.svg';
import How2Sell from '../images/how-to-sell/nav-icons/how_to_sell.inline.svg';
import AcceptedDevices from '../images/how-to-sell/nav-icons/accepted_devices.inline.svg';
import PaymentProcess from '../images/how-to-sell/nav-icons/payment_process.inline.svg';
import DevicePreparation from '../images/how-to-sell/nav-icons/device_preparation.inline.svg';


export const HOW_TO_SELL = {
    "page": "how-to-sell",
    "questions": [
        {
            "icon": <WhereToSell /> ,
            "title": "Where to sell",
            "url": '#where-to-sell'
        },
        {
            "icon": <How2Sell /> ,
            "title": "How to sell",
            "url": '#how-to-sell'
        },
        {
            "icon": <AcceptedDevices /> ,
            "title": "Accepted devices",
            "url": '#accepted-devices'
        },
        {
            "icon": <PaymentProcess /> ,
            "title": "Payment Process",
            "url": '#payment-process'
        },
        {
            "icon": <DevicePreparation /> ,
            "title": "Device preparation",
            "url": '#device-preparation'
        },
    ],
    "answers": [
        {
            "title": "Where to sell your device",
            "id": '#where-to-sell',
            "texts": [
                "Selling your old devices is always a better choice than throwing them away. For a start, you can get paid for them, and get some extra cash to put towards your next iPhone. As well as that, it's much more environmentally friendly - recycling companies make sure that old gadgets are re-used, fixed up, or disposed of in a safe way.",
                "If you’re considering upgrading to a new iPhone, you could help offset the cost by selling your current one. Or maybe you have an old iPhone you no longer use and just want to get some cash for it. ",
                "Dust off your old devices or even newer ones, and find out how you can sell them for the best price.",
                "At Swaptecs, we buy back many older iPhone models in three simple steps. First, get an instant quote on our website. Next, ship us your device(s) with a pre-paid shipping label. Finally, get paid!"
            ]
        },
        {
            "title": "How to sell your iPhone",
            "id": 'how-to-sell',
            "texts": [
                "It couldn't be easier to sell your old gadget for the best price with Swaptecs. Here is how to do it.",
                <><b>Place your order</b> - Simply search for your old device and answer a few questions to get an instant quote to see how much it's worth. Choose one of our payment methods, enter your details and job done!</>,
                <><b>Send your device</b> - if you didn’t request our packaging, we’ll email a prepaid shipping label to you instantly so you can send us your phone right away. If you don’t see the email from us in your inbox, please check your spam folder. Put your phone in the package with our prepaid shipping label affixed to it. Drop the packaged phone off at the Post Office or give it to your mail carrier. Make sure to send in the device as soon as possible!</>,
                <><b>Get paid fast</b> - Once we receive your device, we check your order and if all is correct we'll make payment right away after the inspection. You smile, we smile</>
            ]
        },
        {
            "title": "Accepted devices",
            "id": 'accepted-devices',
            "texts": [
                "Swaptecs is a great place where you can sell iPhones. Here are the current devices being accepted by Swaptecs:"
            ],
            "checklist": [
                "iPhone 11 Pro Max",
                "iPhone 11 Pro",
                "iPhone 11",
                "iPhone XS",
                "iPhone XS Max",
                "iPhone X",
                "iPhone XR",
                "iPhone 8 Plus",
                "iPhone 8",
                "iPhone 7 Plus",
                "iPhone 7"
            ]
        },
        {
            "title": "Payment process",
            "id": 'payment-process',
            "texts": [
                "It should only take a day or two for it to be processed. (It depends on the time of day it gets to us) If the model and condition are confirmed during the inspection, the payment is processed within 3-5 business days. ",
                "We currently offer one payment method – PayPal. You'll be asked to fill the payment address.",
                "Your payment will be sent to you when we receive and inspect your item(s) which is typically within 72 hours of receiving your item(s) but may take up to five business days. We do offer an expedited option that guarantees 24 hour processing and a faster shipping method.",
                "An email will always be sent when a payment is processed and will include details regarding when you can expect to receive it."
            ],
        },
        {
            "title": "How to get device ready for sale",
            "id": 'device-preparation',
            "texts": [],
            "abstracts": [
                {
                    "title": "Unlock your iPhone",
                    "texts": [
                        "An iPhone that can be used anywhere, on any carrier, is an iPhone that can be bought by anyone, in any region. This is especially true for travelers or resellers who operate internationally. So, if your iPhone is locked to a specific carrier, find out if you're eligible to have it unlocked. Some carriers charge for it and some require a specific contract status before they'll unlock. If there is a charge, and it's not high, it could still be worth it to you to pay for the unlock since it could increase your resale value by an equal or greater amount"
                    ]
                },
                {
                    "title": "Take good care of it",
                    "texts": [
                        "This probably goes without saying, but you’ll get the most money if your iPhone is in pristine shape. That means keeping it in a case to prevent bumps and dings to the corners. But if your phone shows some wear and tear, don’t worry — it’s still sellable, you’ll just get a little less. Keeping your original box and including the charger and cable also helps you get the most money"
                    ]
                },
                {
                    "title": "Back up your data",
                    "texts": [
                        "Be sure to get all the data out of your old iPhone. A backup to iCloud or iTunes is the most convenient way to sync all the data to your new iPhone. But because an iPhone backup is pretty self-contained, you may also want to separately make sure your photos are synced to Google Photos, Dropbox, iCloud Photos, or another cloud storage solution."
                    ]
                },
                {
                    "title": "Unpair your Apple Watch",
                    "texts": [
                        "If applicable. You can do this in the Watch app on the phone."
                    ],
                    "list": [
                        <>1. Tap the <b>My Watch</b> tab.</>,
                        "2. An entry for your watch should appear near the top of the app. Tap it.",
                        <>3. Tap the <b>(i)</b> button next to your watch.</>,
                        <>4.  Tap <b>Unpair Apple Watch</b></>
                    ]
                },
                {
                    "title": "Sign out of your Apple account",
                    "texts": [
                        "Sign out of your iCloud and iTunes accounts, too. This is a crucial step — if you erase your phone without signing out first, it will still have Activation Lock enabled and the buyer won't be able to set it up.",
                        <>Head to the top of the Settings app: Just tap your name, then scroll to the bottom of the next screen and tap Sign Out. If you’re switching from an iPhone to an Android phone for some bizarre reason, you also need to de-register your phone number from iMessage, by going to <b>Settings > Messages</b> and turning off the switch labeled iMessage.</>
                    ]
                },
                {
                    "title": "Reset the phone",
                    "texts": [
                        <>Once you’re sure your iPhone is all backed up and ready to be fully erased, head to the <b>Settings</b> and tap <b>General > Reset</b>. The nuclear option here is Erase All Content and Settings; if you're selling or giving away your phone, this is what you want to do.</>,
                        "You may be prompted to turn off Find My iPhone and sign out of iCloud, if you haven’t already — otherwise iOS’s Activation Lock will prevent the phone’s next owner from activating it. Confirm that you want to erase all the data, and your phone will be securely wiped and restored to factory settings."
                    ]
                }
            ]
        }
    ]
}