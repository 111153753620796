import React from "react";
import '../../style/how-to-sell.scss';
import {Nav} from "../../components";
import {HOW_TO_SELL} from '../../data/how-to-sell';
import Success from '../../images/icons/success.svg';
import Footer from "../../components/footer";

const HowToSell = ({location}) => {
    const {questions, answers} = HOW_TO_SELL

    return <div className='siteWrapper'>
        <Nav
            location={location}
            title={'How to sell with Swaptecs'}
            darkNav={true}
        />

        <div className="pageContainer faq">
            <h1>How to sell</h1>

            <section className="pageContent">
                <ul className="pageMenu">
                    {
                        questions.map(el => <li>
                            <a href={el.url} key={el.title}>
                                {el.icon}<span>{el.title}</span>
                            </a>
                        </li>)
                    }
                </ul>

                <div className="answers">
                    {
                        answers.map(({title, id, texts, checklist, abstracts}) => <div
                                className="answer"
                                key={id}
                                id={id}
                            >
                                <h3>{title}</h3>
                                {texts && texts.map(text => <p key={text}>{text}</p>)}
                                {
                                    checklist && <ul className={'checklist'}>{checklist.map(checkitem => <li
                                        key={checkitem}
                                    >
                                            <div className="icon">
                                                <img src={Success} alt=""/>
                                            </div>
                                            <span>{checkitem}</span>
                                        </li>)}
                                    </ul>
                                }
                                {
                                    abstracts && abstracts.map(({title, texts, list}) => <div
                                        className={'abstract'}
                                        key={title}
                                    >
                                        <h4>{title}</h4>
                                        {texts.map(text => <p key={text}>{text}</p>)}
                                        {list && <ul>{list.map(el => <li key={list}>{el}</li>)}</ul>}
                                    </div>)
                                }

                            </div>
                        )
                    }
                </div>
            </section>
        </div>

        <Footer />
    </div>
}

export default HowToSell;